import HeadLayout from 'components/HeadLayout';
import AppLayout from 'components/AppLayout';
import BrandsGrid from 'components/BrandsGrid/BrandsGrid';
import ArrowRight from 'assets/icons/arrow-right-black.svg';
import ServicesGrid from 'components/ServicesGrid';
import ImageLinkBox from 'components/ImageLinkBox/ImageLinkBox';
import Grid2x2 from 'components/Grid2x2';
import { useEffect, useState } from 'react';
import { CMSConstants } from 'config/constants';
import BlogPostListItem from 'components/BlogPostListItem';
import { urls } from 'config/urls';
import TestimonialSection from 'components/TestimonialSection';
import { useTranslation } from 'react-i18next';
import styles from './Home.module.scss';

const parseUrl = relative => {
  return `
    ${CMSConstants.BASE_URL}${relative}`;
};
export default function Home() {
  const [lastPosts, setLastPosts] = useState(null);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    fetch(`${CMSConstants.BASE_URL}/posts?_sort=fecha:DESC&public=true`)
      .then(resp => resp.json())
      .then(data => {
        setLastPosts(data.slice(0, 4));
        // TODO: Tratar
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <>
      <HeadLayout />
      <AppLayout>
        <section className={styles.bannerSection}>
          <a className={styles.conocenosLink} href={urls.nosotros.link}>
            {t('HomePage.BannerSection.linkBottomRight')} <ArrowRight />
          </a>
          <div className={styles.content}>
            <p className={styles.caption}>
              {t('HomePage.BannerSection.caption')}
            </p>
            <h1 className={styles.titleH1}>
              {t('HomePage.BannerSection.title')}
            </h1>
            <h1 className={styles.titleH1}>
              {t('HomePage.BannerSection.subtitle')}
            </h1>
            {/* <p className={styles.subtitle}>
              {t('HomePage.BannerSection.subtitle')}
            </p> */}
          </div>
        </section>
        <section className={styles.clientesSection}>
          <Grid2x2
            a={
              <p className="caption">{t('HomePage.ClientesSection.caption')}</p>
            }
            c={
              <>
                <h2>{t('HomePage.ClientesSection.title')}</h2>
                <p>{t('HomePage.ClientesSection.subtitle')}</p>
              </>
            }
            d={<BrandsGrid />}
          />
        </section>

        <section className={styles.serviciosSection}>
          <div className={styles.head}>
            <Grid2x2
              a={
                <p className="caption">
                  {t('HomePage.ServiciosSection.caption')}
                </p>
              }
              c={<h2>{t('HomePage.ServiciosSection.title')}</h2>}
              d={<p>{t('HomePage.ServiciosSection.subtitle')}</p>}
            />
          </div>
          <ServicesGrid />
        </section>

        <section className={styles.casesSection}>
          <div className={styles.head}>
            <Grid2x2
              a={
                <p className="caption">{t('HomePage.CasesSection.caption')}</p>
              }
              c={<h2>{t('HomePage.CasesSection.title')}</h2>}
              d={<p>{t('HomePage.CasesSection.subtitle')}</p>}
            />
          </div>
          <div className={styles.sampleCase}>
            <ImageLinkBox
              title="Elecsum"
              text={[t('CaseStudiesDetail.elecsum.header.title')]}
              image="/images/case-studies/elecsum/case-study-elecsum-head.webp"
              link={urls['case-elecsum'].link}
              label={t('CaseStudiesDetail.general.discover')}
            />
          </div>
        </section>

        <TestimonialSection
          caption={t('Testimonials.caption')}
          title={<h2>{t('Testimonials.title')}</h2>}
          elements={[
            {
              image:
                '/images/case-studies/smart-money/case-study-smart-money-testimonial.webp',
              name: 'Guillermo Casanovas',
              company: 'Caixabank',
              quote: t('Testimonials.ub.quote')
            },
            {
              image: '/images/case-studies/ub/case-study-ub-testimonial.webp',
              name: 'Mel Solé',
              company: 'Universitat de Barcelona',
              quote: t('Testimonials.caixabank.quote')
            },
            {
              image:
                '/images/case-studies/manvert/case-study-manvert-testimonial.webp',
              name: 'Enric Bonet',
              company: 'Biovert',
              quote: t('Testimonials.biovert.quote')
            }
            /* {
              image:
                '/images/case-studies/elecsum/case-study-elecsum-testimonial.jpg',
              name: 'Xavier Bou',
              company: 'Elecsum',
              quote: t('Testimonials.elecsum.quote')
            } */
          ]}
        />
        {i18n.language === 'es' && (
          <section className={styles.blogSection}>
            <div className={styles.head}>
              <Grid2x2
                a={
                  <p className="caption">{t('HomePage.BlogSection.caption')}</p>
                }
                c={<h1>{t('HomePage.BlogSection.title')}</h1>}
                d={
                  <>
                    <p>{t('HomePage.BlogSection.subtitle')}</p>
                  </>
                }
              />
            </div>
            {lastPosts && (
              <div className={styles.lastGrid}>
                {lastPosts.slice(0, 2).map(post => {
                  return (
                    <BlogPostListItem
                      key={post.id}
                      image={parseUrl(post.head_image.url)}
                      author={post.authors.name}
                      title={post.title}
                      subtitle={post.subtitle}
                      avatar={parseUrl(post.authors.avatar.url)}
                      position={post.authors.position}
                      link={`${urls.blog.link}/${post.slug}`}
                      date={post.fecha}
                    />
                  );
                })}
              </div>
            )}
          </section>
        )}

        {/* <section className={classNames(styles.newsletterSection)}>
          <Grid2x2
            a={<p className="caption">EUNOIA DIGITAL</p>}
            c={<h2>Suscríbete para recibir nuestro contenido más destacado</h2>}
            d={
              <div>
                <p>
                  Descubre las últimas novedades sobre UI/UX, Desarrollo de
                  productos digitales y los mejores tips de Diseño en nuestra
                  Newsletter.
                </p>
                <form>
                  <div className={styles.field}>
                    <input
                      type="text"
                      placeholder="Introduce tu dirección de -email"
                    />
                  </div>
                  <button type="submit">Subscribirme</button>
                </form>
              </div>
            }
          />
        </section> */}
      </AppLayout>
    </>
  );
}
