/* eslint-disable no-unused-vars */
import React from 'react';

import Link from 'components/Link';
import ArrowRight from 'assets/icons/arrow_right_long.svg';
import Divider from 'components/Divider';
import moment from 'moment';
import Grid2x2 from 'components/Grid2x2';
import styles from './BlogPostListItem.module.scss';

export default function BlogPostThumbnail(props) {
  const { image, author, title, link, subtitle, date } = props;
  return (
    <Link url={link}>
      <Grid2x2
        customClass={styles.container}
        a={
          <div className={styles.imgContainer}>
            <img
              className={styles.nxtimage}
              src={image}
              alt={`Eunoia Digital - ${title}`}
            />

            <p className={styles.date}>
              {moment(date).locale('es').format('LL')}
            </p>
          </div>
        }
        b={
          <div className={styles.dataContainer}>
            <p className={styles.author}>{author}</p>
            <h4>{title}</h4>
            <p className={styles.subtitle}>{subtitle}</p>
            <p className={styles.link}>
              Descubre más
              <ArrowRight />
            </p>
            <Divider width="100%" />
          </div>
        }
      />
    </Link>
  );
}

BlogPostThumbnail.defaultProps = {
  even: false,
  odd: false,
  author: 'Paula Leeson',
  position: 'Project Manager',
  title:
    'El poder de la jerarquía visual en el diseño de experiencias de usuario',
  link: '/blog',
  avatar: '/images/placeholders/TeamCard.png',
  date: ''
};
