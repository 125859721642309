import React from 'react';
import Colors from 'styles/Colors';
import styles from './Divider.module.scss';

export default function Divider(props) {
  const { direction, color, width, height } = props;
  const getStyles = () => {
    if (direction === 'vertical') {
      return {
        width: '3px',
        height,
        backgroundColor: color
      };
    }
    return {
      width,
      height,
      backgroundColor: color
    };
  };
  return <div className={styles.container} style={getStyles()} />;
}

Divider.defaultProps = {
  direction: 'horizontal',
  color: Colors.PRIMARY,
  width: '90%',
  height: '2px'
};
