import classNames from 'classnames';
import propTypes from 'prop-types';
import styles from './Grid2x2.module.scss';

export default function Grid2x2(props) {
  const { a, b, c, d, customClass } = props;
  return (
    <div className={classNames(styles.container, customClass)}>
      <div className={styles.a}>{a}</div>
      <div className={styles.b}>{b}</div>
      <div className={styles.c}>{c}</div>
      <div className={styles.d}>{d}</div>
    </div>
  );
}

Grid2x2.defaultProps = {
  customClass: ''
};

Grid2x2.propTypes = {
  customClass: propTypes.string
};
