import { useState, useEffect, useRef } from 'react';

function useOnScreen(rootMargin = '0px', once = true) {
  const ref = useRef();
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && once) {
          observer.unobserve(ref.current);
        }
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, [ref, rootMargin, once]);
  return { ref, visible: isIntersecting };
}
export default useOnScreen;
