const Colors = {
  PRIMARY: '#122b3f',
  SECONDARY: '#3dbfee',
  NEUTRAL: '#f7f7f7',
  DESIGN: '#e7bb7c',
  DEVELOPMENT: '#7ce1e7',
  IMPLANT: '#f2b9df',
  MARKETING: '#9BC5A2',

  BLACK: '#000000',
  GRAY800: '#4e4e4e',
  GRAY400: '#6f6f6f',
  GRAY200: '#9a9a9a',
  GRAY100: '#dedede',
  GRAY50: '#f9f9f9',
  WHITE: '#ffffff'
};

export default Colors;
