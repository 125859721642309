import React from 'react';
import propTypes from 'prop-types';

import RightIcon from 'assets/icons/arrow_right_long.svg';
import Link from 'components/Link';
import styles from './ImageLinkBox.module.scss';

export default function ImageLinkBox(props) {
  const { image, title, text, link, label } = props;
  return (
    <Link url={link}>
      <div className={styles.root}>
        <img src={image} alt={title} />

        <div className={styles.box}>
          <p className={styles.title}>{title}</p>
          {text.map(p => {
            return (
              <p key={p} className="normal">
                {p}
              </p>
            );
          })}
          <span className={styles.link}>
            {label}
            <RightIcon />
          </span>
        </div>
      </div>
    </Link>
  );
}

ImageLinkBox.propTypes = {
  image: propTypes.string,
  title: propTypes.string,
  text: propTypes.arrayOf(propTypes.string),
  link: propTypes.string,
  label: propTypes.string
};

ImageLinkBox.defaultProps = {
  image: '/images/placeholders/ImageLinkBox.jpg',
  title: 'Elecsum',
  text: [
    'Un ecosistema que va a transformar la manera en la que sus clientes se relacionan con empresas del sector y con el propio banco.'
  ],
  link: 'https://www.eunoia.es',
  label: 'Descubre más'
};
