import React from 'react';

import styles from './BrandsGrid.module.scss';

export default function BrandsGrid() {
  const brands = [
    { img: '/images/brands-ey.svg', alt: 'eunoia-client-ey' },
    { img: '/images/brands-bayer-eunoia.svg', alt: 'eunoia-client-bayer' },
    { img: '/images/brands-sabadell.svg', alt: 'eunoia-client-sabadell' },

    { img: '/images/brands-inditex.svg', alt: 'eunoia-client-inditex' },

    { img: '/images/brands-perrigo.svg', alt: 'eunoia-client-perrigo' },
    { img: '/images/brands-caixabank.svg', alt: 'eunoia-client-caixabank' },

    { img: '/images/brands-brownie.svg', alt: 'eunoia-client-brownie' },

    { img: '/images/brands-manvert.svg', alt: 'eunoia-client-biovert' },
    {
      img: '/images/brands-grupo-eunoia.svg',
      alt: 'eunoia-client-grupo-godó'
    },

    { img: '/images/brands-elecsum.svg', alt: 'eunoia-client-elecsum' },
    {
      img: '/images/brands-catalonia-hotels.svg',
      alt: 'eunoia-client-catalonia-hotels'
    },
    {
      img: '/images/brands-vidacaixa-eunoia.svg',
      alt: 'eunoia-client-vidacaixa'
    }
  ];
  return (
    <div className={styles.container}>
      {brands.map(brand => {
        return (
          <img
            key={brand.alt}
            src={brand.img}
            alt={brand.alt}
            title={brand.alt}
          />
        );
      })}
    </div>
  );
}
