import Grid2x2 from 'components/Grid2x2';
import { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import styles from './TestimonialSection.module.scss';

export default function TestimonialSection(props) {
  const { title, caption, elements } = props;
  const [index, setIndex] = useState(0);

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex);
  };
  return (
    <section className={styles.container}>
      <Grid2x2
        customClass={styles.gridContainer}
        a={<p className="caption">{caption}</p>}
        c={title}
        d={
          <Carousel
            className={styles.carouselContainer}
            activeIndex={index}
            onSelect={handleSelect}
            controls={false}
          >
            {elements.map((item, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Carousel.Item key={i} className={styles.carouselItem}>
                  <div className={styles.itemWrapper}>
                    <div className={styles.testimonial}>
                      <img src={item.image} alt="" />
                      <div className={styles.info}>
                        <p className={styles.name}>{item.name}</p>
                        <p className={styles.company}>{item.company}</p>
                      </div>
                    </div>
                    <p className={styles.quote}>{item.quote}</p>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        }
      />
    </section>
  );
}
TestimonialSection.defaultProps = {
  /* Element item atributes: image, name, company,quote */
  elements: []
};
