import Link from 'components/Link';
import { urls } from 'config/urls';
import RightIcon from 'assets/icons/arrow_right_long.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useOnScreen from 'hooks/useNearScreen';
import styles from './ServicesGrid.module.scss';

export default function ServicesGrid() {
  const { t } = useTranslation();
  const sectionsRef = {
    design: useOnScreen(),
    development: useOnScreen(),
    marketing: useOnScreen(),
    implant: useOnScreen()
  };
  return (
    <div className={styles.servicesWrapper}>
      <Link url={urls.design.link}>
        <div
          ref={sectionsRef.design.ref}
          className={classNames(styles.serviceRow, {
            [styles.slideInLeft]: sectionsRef.design.visible
          })}
        >
          <div className={styles.image}>
            <img
              src="/images/design-eunoiadigital.png"
              alt="Eunoia Digital Design"
            />
          </div>
          <div className={styles.data}>
            <div className={styles.wrapper}>
              <h3>{t('ServiciosGrid.Diseno.title')}</h3>
              <p>{t('ServiciosGrid.Diseno.description')}</p>
              <span className={styles.link}>
                {t('ServiciosGrid.Diseno.link')}
                <RightIcon />
              </span>
            </div>
          </div>
        </div>
      </Link>

      <Link url={urls.productos.link}>
        <div
          ref={sectionsRef.development.ref}
          className={classNames(styles.serviceRow, styles.odd, {
            [styles.slideInRight]: sectionsRef.development.visible
          })}
        >
          <div className={styles.data}>
            <div className={styles.wrapper}>
              <h3>{t('ServiciosGrid.Desarrollo.title')}</h3>
              <p>{t('ServiciosGrid.Desarrollo.description')}</p>
              <span className={styles.link}>
                {t('ServiciosGrid.Desarrollo.link')}
                <RightIcon />
              </span>
            </div>
          </div>
          <div className={styles.image}>
            <img
              src="/images/development-eunoiadigital.png"
              alt="Eunoia Digital Development"
            />
          </div>
        </div>
      </Link>

      <Link url={urls.marketing.link}>
        <div
          ref={sectionsRef.marketing.ref}
          className={classNames(styles.serviceRow, {
            [styles.slideInLeft]: sectionsRef.marketing.visible
          })}
        >
          <div className={styles.image}>
            <img
              src="/images/marketing-eunoiadigital.png"
              alt="Eunoia Digital Marketing"
            />
          </div>
          <div className={styles.data}>
            <div className={styles.wrapper}>
              <h3>{t('ServiciosGrid.Marketing.title')}</h3>
              <p>{t('ServiciosGrid.Marketing.description')}</p>
              <span className={styles.link}>
                {t('ServiciosGrid.Marketing.link')}
                <RightIcon />
              </span>
            </div>
          </div>
        </div>
      </Link>

      <Link url={urls.implant.link}>
        <div
          ref={sectionsRef.implant.ref}
          className={classNames(styles.serviceRow, styles.odd, {
            [styles.slideInRight]: sectionsRef.implant.visible
          })}
        >
          <div className={styles.data}>
            <div className={styles.wrapper}>
              <h3>{t('ServiciosGrid.Implant.title')}</h3>
              <p>{t('ServiciosGrid.Implant.description')}</p>
              <span className={styles.link}>
                {t('ServiciosGrid.Implant.link')}
                <RightIcon />
              </span>
            </div>
          </div>
          <div className={styles.image}>
            <img
              src="/images/digitalimplant-eunoiadigital.png"
              alt="Eunoia Digital Implant"
            />
          </div>
        </div>
      </Link>
    </div>
  );
}
